import Helper from "@/utils/helper";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getSession, signOut } from "next-auth/react";
import axiosRetry from "axios-retry";

export interface ResponseApiClient<T> {
	data?: T | null;
	errors?: string[];
	status_code?: number;
}

// Array of URLs that need to be retried
const retryUrls: string[] = [
	"/orders/cart/public/pay",
	"/orders/cart/pay",
	"/cashflowreceipts",
];

const AxiosInstance = axios.create({
	timeout: 1000 * 60,
	headers: {
		"Content-Type": "application/json",
	},
	"axios-retry": {
		retries: 3, // Truyền số lần retry ở đây
	},
});
axiosRetry(AxiosInstance, {
	retries: AxiosInstance.defaults["axios-retry"]?.retries || 3,

	retryCondition: (error) => {
		const status = error?.response?.status || 500;
		if (status >= 500 || status < 400) {
			const currentUrl = error?.config?.url || "";
			// return retryUrls.some((url) => currentUrl.includes(url));
			return true;
		} else {
			return false;
		}
	},
	retryDelay: (retryCount) => {
		return retryCount * 1000; // delay between retries (1s)
	},

	shouldResetTimeout: true,
	onRetry: (retryCount, error, requestConfig) => {
		// requestConfig.data = JSON.parse(
		// 	JSON.stringify(AxiosInstance.defaults.data)
		// );
	},
});

AxiosInstance.interceptors.request.use(async (request) => {
	const requestUrl = request.url || "";
	const arrUser = [
		"customers",
		"users",
		"user",
		"cart",
		"invoice",
		"locations",
		"wishlists",
		"employees",
		"shippingcarriers",
		"preorder",
		"deliveries",
		"logout",
		"cashflowreceipts",
	];

	if (
		!requestUrl.includes("public") &&
		!requestUrl.includes("login") &&
		arrUser.some((i) => requestUrl.includes(i))
	) {
		let token = "";

		if (!Helper.isServer()) {
			const tokenLocal = localStorage.getItem("JWT");
			if (tokenLocal) {
				token = tokenLocal;
			} else {
				const session = await getSession();
				const tokenSession = session?.user.jwt;
				if (tokenSession) {
					token = tokenSession;
					localStorage.setItem("JWT", token);
				}
			}
		} else {
			const session = await getSession();
			token = session?.user.jwt || "";
		}

		if (token) {
			request.headers.Authorization = `${token}`;
		}
	}

	// If this is GET method, append jwt to param, to prevent prefetch request
	if (request.method === "get") {
		request.params = {
			...request.params,
		};
	} else {
		// Append company_id to POST/PUT request data
		// request.data = {
		// 	...request.data,
		// };
	}

	return request;
});

AxiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (error) => {
		// const originalRequest = error.config as AxiosRequestConfig & {
		// 	_retryCount?: number;
		// };
		// const currentUrl = originalRequest.url || "";

		// if (retryUrls.some((url) => currentUrl.includes(url))) {
		// 	originalRequest._retryCount = originalRequest._retryCount || 0;

		// 	if (originalRequest._retryCount < 3) {
		// 		originalRequest._retryCount++;
		// 		originalRequest.timeout = 5000;
		// 		originalRequest.data = JSON.parse(originalRequest.data);
		// 		await new Promise((res) => setTimeout(res, 1000));
		// 		return AxiosInstance(originalRequest);
		// 	}
		// }

		if (error.response) {
			if (error.response.status >= 500) {
				throw new Error("Server Error");
			}
			if (error.response.status === 401) {
				const url = error.response.config?.url as string;
				Helper.removeJWTLocal();
				const callbackUrl = url.includes("/pos") ? "/pos" : "/";
				await signOut({ redirect: true, callbackUrl });
			}
		}

		return Promise.reject(error);
	}
);

export default AxiosInstance;
