import BaseApi from "@/common/api/BaseApi";
import ErrorBase, { ErrorServer } from "@/common/api/Error";
import Helper from "@/utils/helper";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FetchData, FetchDataV2 } from "./Api";
import { notFound } from "next/navigation";

type LogError = {
  error: ErrorBase;
  request?: any;
  startTime?: number;
};
function logError({ error, request, startTime }: LogError) {
  const logValue = {
    request: request && request,
    response: {
      status: error.statusCode,
      error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    },
    executionTime:
      startTime && (Date.now() / 1000 - startTime).toFixed(2) + "s",
  };

  if (Helper.isDebug()) {
    console.log("ERROR:", logValue);
  }
}
function logResponse({
  request,
  response,
  startTime,
}: {
  request: any;
  response: any;
  startTime: number;
}) {
  // function deepLog(obj: any, indent: number = 0): void {
  // 	for (const key in obj) {
  // 		if (typeof obj[key] === "object" && obj[key] !== null) {
  // 			console.log(obj[key]);
  // 			// console.log(" ".repeat(indent * 2) + key + ": {");
  // 			// deepLog(obj[key], indent + 1);
  // 			// console.log(" ".repeat(indent * 2) + "}");
  // 		} else {
  // 			console.log(" ".repeat(indent * 2) + key + ": " + obj[key]);
  // 		}
  // 	}
  // }
  const logValue = {
    request: {
      url: request.url,
      body: request?.data || null,
      params: request?.params || null,
    },
    response: {
      data: response?.data || null,
      status: response?.status || null,
    },
    executionTime: (Date.now() / 1000 - startTime).toFixed(2) + "s",
  };

  if (Helper.isDebug()) {
    // console.log("🚀RESPONSE:", logValue);
  }
}
function debugServer(
  url: string,
  res: any,
  status: number,
  exuctionTime: any,
  type?: "error" | "res"
) {
  const typeLog = type ?? "res";
  if (Helper.isDebug()) {
    if (typeLog === "res") {
      console.log("FetchResponse:", {
        URL: url,
        HTTP_Status: status,
        Data: res,
        ExecutionTime: exuctionTime,
      });
    } else {
      console.log("FetchErrors:", {
        URL: url,
        HTTP_Status: status,
        Data: res,
        ExecutionTime: exuctionTime,
      });
    }
  }
}

async function handleDataResponse<T, D>(
  promise: Promise<any>,
  defaultValue: D
): Promise<T | D> {
  let data: D | T = defaultValue;
  await promise
    .then((res) => {
      if (typeof res !== "object" && typeof res !== "string") {
        data = defaultValue;
      } else {
        data = res as T;
      }
    })
    .catch((err) => {
      data = defaultValue;
    });

  return data;
}

async function handleResponse<T>(
  response: Response | null,
  fetchOptions: RequestInit,
  startTime: number,
  error?: any
): Promise<FetchData<T>> {
  const nonError = { errors: [], status: 200 } as ErrorServer;
  if (response === null) {
    const rejectError: ErrorServer = error
      ? {
          errors: BaseApi.handleError(error).errors,
          status: BaseApi.handleError(error).statusCode,
        }
      : { errors: ["api_net_work_error"], status: 500 };
    // logError({
    // 	request: fetchOptions,
    // 	status: rejectError.status,
    // 	error: rejectError.error,
    // 	startTime: startTime,
    // });
    return {
      data: null,
      error: rejectError,
      status: rejectError.status,
    };
  }
  if (200 <= response.status && response.status < 300) {
    // Trạng thái 200 OK, không có lỗi.
    const data = await handleDataResponse<T, null>(response.json(), null);
    logResponse({
      request: fetchOptions,
      response: { ...response, data: data },
      startTime: startTime,
    });
    return {
      data: data,
      error: nonError,
      status: response.status,
    };
  } else if (400 <= response.status && response.status < 500) {
    // Trạng thái lỗi từ client (ví dụ: 404 Not Found, 403 Forbidden).
    let err: Pick<ErrorServer, "errors" | "error_detail"> = {
      errors: [],
      error_detail: [],
    };

    await response
      .json()
      .then((res) => {
        if (!res.hasOwnProperty("error")) {
          err.errors = ["erorr_not_found"];
        } else {
          if (res.error.hasOwnProperty("error_detail")) {
            err.error_detail = res.error.error_detail;
          }
          err.errors = res.error;
        }
      })
      .catch((error) => {
        err.errors = ["exception_error"];
      });

    // logError({
    // 	request: fetchOptions,

    // 	status: response.status,
    // 	error: err.error,
    // 	startTime: startTime,
    // });

    return {
      data: null,
      error: {
        errors: err.errors,
        status: response.status,
        error_detail: err.error_detail,
      },
      status: response.status,
    };
  } else if (500 <= response.status && response.status < 600) {
    // Trạng thái lỗi từ server (ví dụ: 500 Internal Server Error).
    // logError({
    // 	request: fetchOptions,
    // 	status: response.status,
    // 	error: { error: ["server_error"], status: response.status },
    // 	startTime: startTime,
    // });
    return {
      data: null,
      error: { errors: ["server_error"], status: response.status },
      status: response.status,
    };
  } else {
    // Trạng thái ngoại lệ không nằm trong khoảng từ 200 đến 500.
    // logError({
    // 	request: fetchOptions,
    // 	status: response.status,
    // 	error: { error: ["expected_error"], status: response.status },
    // 	startTime: startTime,
    // });
    return {
      data: null,
      error: { errors: ["expected_error"], status: response.status },
      status: response.status,
    };
  }
}

function handleAxiosResponse<T>(
  response: AxiosResponse<T> | null,
  options: AxiosRequestConfig,
  error?: any,
  start?: number
): FetchData<T> {
  const startTime = start || 0;
  if (error) {
    const rejectErorr = BaseApi.handleError(error);
    // logError({
    // 	request: options,
    // 	status: rejectErorr.statusCode,
    // 	error: rejectErorr,
    // 	startTime: startTime,
    // });
    return {
      data: null,
      error: {
        errors: rejectErorr.errors,
        status: rejectErorr.statusCode,
        error_detail: rejectErorr.error_detail,
        active_phone: rejectErorr.active_phone,
        time: rejectErorr.time,
      },
      status: rejectErorr.statusCode,
    };
  } else if (response && response.hasOwnProperty("data")) {
    const typeInvalid = ["string", "object", "boolean"];
    if (!typeInvalid.includes(typeof response.data)) {
      return {
        data: null,
        error: {
          errors: ["response_not_invalid"],
          status: 406,
        },
        status: 406,
      };
    } else {
      logResponse({
        request: options,
        response: response,
        startTime: startTime,
      });
      return {
        data: response.data,
        error: null,
        status: response.status,
      };
    }
  } else {
    // logError({
    // 	request: options,
    // 	status: 406,
    // 	error: ["error_not_found"],
    // 	startTime: startTime,
    // });
    return {
      data: null,
      error: {
        errors: ["error_not_found"],
        status: 406,
      },
      status: 406,
    };
  }
}

// function handleProcessError(error: LogError):FetchDataV2<null> {
// 	logError(error);
// 	return {

// 		data: null,
// 		error:error.error,
// 		status: error.error.statusCode,
// 	};
// }
function handleError404(error: unknown) {
  const dataError = getDataError(error);
  const status = dataError.statusCode;
  if (status === 404) {
    notFound();
  }
}

function handleError5x(error: unknown) {
  const dataError = getDataError(error);
  const status = dataError.statusCode;
  if (status === 500) {
    throw new Error("server_error");
  }
}

function getDataError(error: unknown) {
  // if (error instanceof Error) {
  //   return new ErrorBase({
  //     statusCode: 0,
  //     errors: [JSON.stringify(error.message)],
  //   });
  // }
  return BaseApi.handleError(error);
}

export {
  logError,
  debugServer,
  handleDataResponse,
  handleAxiosResponse,
  handleResponse,
  handleError404,
  handleError5x,
  getDataError,
};
